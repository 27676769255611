import { Drawer } from "flowbite";
import {
  drawBarChart,
  drawPieChart,
  drawStackedBarChart,
  drawRadarChart,
  drawFunnelChart,
} from "./charts";
import { Accordion } from "flowbite";
import { thousandSeperator } from "./utils";

export var initSummaryDrawer = (drawer_id, coordinateText, response) => {
  // set the drawer menu element
  let drawerElement = document.getElementById(drawer_id);
  if (!drawerElement) {
    return;
  }

  if (coordinateText) {
    $("#drawer-right-coordinate").parent().show();
    $("#drawer-right-coordinate").html(coordinateText);
  } else {
    $("#drawer-right-coordinate").parent().hide();
  }

  $("#drawer-right-summary-barchart").empty();
  $("#drawer-right-summary-stackedbarchart").empty();
  $("#drawer-right-summary-cropschart").empty();

  if (response.district) {
    $("#drawer-right-name").html(response.district[0].distrito);

    let seriesData = [
      parseFloat(response.district[0].scarce_ha),
      parseFloat(response.district[0].regular_ha),
      parseFloat(response.district[0].irrigated_ha),
      parseFloat(response.district[0].uncultivated_ha),
    ];
    let sorted = seriesData.sort((a, b) => a - b);
    let yMin = Math.round(sorted[0] / 4);
    sorted = sorted.toReversed();
    let yMax = Math.round(sorted[0]);

    // [10000, 8000, 300]
    if ((yMax - sorted[1]) / sorted[1] > 0.1) {
      yMax = Math.round(1.2 * sorted[1]);
    }
    let colors = ["#a0c9a3", "#186118", "#7d73b2", "#e6e64d"];
    drawBarChart(
      {
        series: [
          {
            name: "Area (ha)",
            data: seriesData,
          },
        ],
        x: [
          "Scarce farming",
          "Regular farming",
          "Irrigated farming",
          "Uncultivated",
        ],
      },
      "drawer-right-summary-barchart",
      "",
      colors,
      yMin,
      yMax
    );

    // drawPieChart(
    //   {
    //     series: [
    //       parseFloat(response.district[0].scarce_ha),
    //       parseFloat(response.district[0].regular_ha),
    //       parseFloat(response.district[0].irrigated_ha),
    //       parseFloat(response.district[0].uncultivated_ha),
    //     ],
    //     labels: [
    //       "Scarce farming",
    //       "Regular farming",
    //       "Irrigated farming",
    //       "Uncultivated",
    //     ],
    //   },
    //   "drawer-right-summary-barchart",
    //   $("#drawer-right-summary-barchart").width() + 40
    // );
  } else {
    $("#drawer-right-summary-barchart").html(
      "No district statistic available for this location."
    );
  }

  if (response.strata) {
    let html = `
      <div id="accordion-strata" data-accordion="collapse">
        <h2 id="accordion-strata-scarce">
          <button type="button" class="flex items-center justify-between mt-1 w-full p-2 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-2" data-accordion-target="#accordion-strata-scarce-body" aria-expanded="true" aria-controls="accordion-strata-scarce-body">
            <span>Scarce farming area (ha)</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div id="accordion-strata-scarce-body" class="hidden" aria-labelledby="accordion-strata-scarce">
          <div class="p-0 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <tbody>
        `;
    response.strata[0]["scarce_strata"].forEach((element, idx) => {
      html += `
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td class="w-4 p-2 hidden">
              <div class="flex items-center">
                <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-3 py-2">${element}</td>
            <td class="px-3 py-2 text-right">${thousandSeperator(
              response.strata[0]["scarce_areas_ha"][idx]
            )}</td>
          </tr>
      `;
    });

    html += `
          </tbody>
        </table>
      </div>
    </div>
    <h2 id="accordion-strata-regular">
      <button type="button" class="flex items-center justify-between w-full mt-1 p-2 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-2" data-accordion-target="#accordion-strata-regular-body" aria-expanded="false" aria-controls="accordion-strata-regular-body">
        <span>Regular farming area (ha)</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
      </button>
    </h2>
    <div id="accordion-strata-regular-body" class="hidden" aria-labelledby="accordion-strata-regular">
      <div class="p-0 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <tbody>`;
    response.strata[0]["regular_strata"].forEach((element, idx) => {
      html += `
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td class="w-4 p-2 hidden">
          <div class="flex items-center">
            <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
          </div>
        </td>
        <td class="px-3 py-2">${element}</td>
        <td class="px-3 py-2  text-right">${thousandSeperator(
          response.strata[0]["regular_areas_ha"][idx]
        )}</td>
      </tr>
      `;
    });
    html += `
          </tbody>
        </table>
      </div>
    </div>
    <h2 id="accordion-strata-irrigated">
      <button type="button" class="flex items-center justify-between w-full mt-1 p-2 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-2" data-accordion-target="#accordion-strata-irrigated-body" aria-expanded="false" aria-controls="accordion-strata-irrigated-body">
        <span>Irrigated farming area (ha)</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
      </button>
    </h2>
    <div id="accordion-strata-irrigated-body" class="hidden" aria-labelledby="accordion-strata-irrigated">
      <div class="p-0 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <tbody>`;

    response.strata[0]["irrigated_strata"].forEach((element, idx) => {
      html += `
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td class="w-4 p-2 hidden">
          <div class="flex items-center">
            <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
          </div>
        </td>
        <td class="px-3 py-2">${element}</td>
        <td class="px-3 py-2  text-right">${thousandSeperator(
          response.strata[0]["irrigated_areas_ha"][idx]
        )}</td>
      </tr>
      `;
    });

    html += `</tbody></table></div></div></div>`;

    $("#drawer-right-summary-table").html(html);

    const accordionElement = document.getElementById("accordion-strata");

    const accordionItems = [
      {
        id: "aaccordion-strata-scarce",
        triggerEl: document.querySelector("#accordion-strata-scarce"),
        targetEl: document.querySelector("#accordion-strata-scarce-body"),
        active: true,
      },
      {
        id: "accordion-strata-regular",
        triggerEl: document.querySelector("#accordion-strata-regular"),
        targetEl: document.querySelector("#accordion-strata-regular-body"),
        active: false,
      },
      {
        id: "accordion-strata-irrigated",
        triggerEl: document.querySelector("#accordion-strata-irrigated"),
        targetEl: document.querySelector("#accordion-strata-irrigated-body"),
        active: false,
      },
    ];

    const options = {
      alwaysOpen: true,
      activeClasses:
        "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white",
      inactiveClasses: "text-gray-500 dark:text-gray-400",
      onOpen: (item) => {},
      onClose: (item) => {},
      onToggle: (item) => {},
    };

    // instance options object
    const instanceOptions = {
      id: "accordion-strata",
      override: true,
    };
    const accordion = new Accordion(
      accordionElement,
      accordionItems,
      options,
      instanceOptions
    );
  } else {
    $("#drawer-right-summary-barchart").html(
      "No strata statistic available for this location."
    );
  }

  if (response.visits) {
    drawStackedBarChart(
      {
        series: [
          {
            name: "Scheduled visited",
            group: "visited",
            data: response.visits[0].schedvis,
          },
          {
            name: "Unscheduled visited",
            group: "visited",
            data: response.visits[0].unschedvis,
          },
          {
            name: "Scheduled not visited",
            group: "not visited",
            data: response.visits[0].schednotvis,
          },
          {
            name: "Unscheduled not visited",
            group: "not visited",
            data: response.visits[0].unschednotvis,
          },
        ],
        x: response.visits[0].strata,
      },
      "drawer-right-summary-stackedbarchart"
    );
  } else {
    $("#drawer-right-summary-stackedbarchart").html(
      "No visitation statistic available for this location."
    );
  }

  if (response.crops) {
    let html = `
    <div class="p-0 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th class="hidden"></th>
            <th scope="col" class="px-3 py-2">
                Crop name
            </th>
            <th scope="col" class="px-3 py-2">
                Area (ha)
            </th>
          </tr>
        </thead>
        <tbody>`;

    response.crops[0].name.forEach((element, idx) => {
      html += `
      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td class="w-4 p-2 hidden">
          <div class="flex items-center">
            <input id="checkbox-crop-${idx}" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="checkbox-crop-${idx}" class="sr-only">checkbox</label>
          </div>
        </td>
        <td class="px-3 py-2">${element}</td>
        <td class="px-3 py-2 text-right">${
          response.crops[0].area[idx]
            ? thousandSeperator(response.crops[0].area[idx])
            : "-"
        }</td>
      </tr>
      `;
    });
    html += "</tbody></table></div>";
    $("#drawer-right-summary-cropschart").html(html);
  } else {
    $("#drawer-right-summary-cropschart").html(
      "No crop data available for this location."
    );
  }

  let drawer = initDrawer(drawer_id, "right");
  return drawer;
};

export var initDrawer = (drawer_id, placement = "right") => {
  // set the drawer menu element
  let drawerElement = document.getElementById(drawer_id);
  if (!drawerElement) {
    return;
  }

  // instance options object
  let instanceOptions = {
    id: drawer_id,
    override: true,
  };

  // options with default values
  let options = {
    placement: placement,
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: "",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
    onHide: () => {},
    onShow: () => {},
    onToggle: () => {},
  };

  let drawer = new Drawer(drawerElement, options, instanceOptions);
  drawer.hide();
  return drawer;
};
