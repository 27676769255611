const DARK_CLASS_NAME = "dark";
const DARK_COLOR_THEME = "dark";
const LIGHT_CLASS_NAME = "light";
const LIGHT_COLOR_THEME = "light";
const HIDDEN_CLASS_NAME = "hidden";

const GEO_HOST = "https://mapfra.itc.utwente.nl";

globalThis.GEO_HOST = GEO_HOST;

module.exports = {
  DARK_CLASS_NAME,
  DARK_COLOR_THEME,
  LIGHT_CLASS_NAME,
  LIGHT_COLOR_THEME,
  HIDDEN_CLASS_NAME,
  GEO_HOST,
};
